import { loadingOverlayCount, abortedOverlayCount } from '$stores/loadingStore';
import { get } from 'svelte/store';

export const resetLoadingOverlay = () => {
    loadingOverlayCount.set(0);
    if (__DEBUG_LOADING_OVERLAY__) console.log('reset', get(loadingOverlayCount));
};
export const showLoadingOverlay = () => {
    loadingOverlayCount.update($lc => $lc + 1);
    if (__DEBUG_LOADING_OVERLAY__) console.log('show loading, count:', get(loadingOverlayCount));
};
export const hideLoadingOverlay = () => {
    loadingOverlayCount.update($lc => $lc - 1);
    if (__DEBUG_LOADING_OVERLAY__) console.log('hide loading, count:', get(loadingOverlayCount));
};

export const hasLoadingOverlay = () => get(loadingOverlayCount) > 0;

export const incrementAbortedOverlayCount = () => {
    abortedOverlayCount.update($clc => $clc + 1);
};

export const clearAbortedOverlayCount = () => {
    if (get(abortedOverlayCount) > 0) {
        loadingOverlayCount.update($lc => $lc - get(abortedOverlayCount));
        abortedOverlayCount.set(0);
    }
};
